.container {
  position: relative;
  z-index: 1;
  background-color: var(--header-color);

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    padding: 0 15px;
  }

  @media screen and (min-width: 1280px) {
    padding: 0 25px;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 1px;
    background-color: var(--header-border-color);
  }
}

.logo {
  display: flex;
  justify-content: flex-start;
  max-height: 70%;

  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 60px;
  }

  @media screen and (min-width: 279px) and (max-width: 766px) {
    margin-right: auto;
    max-width: calc(100% - 160px);
  }

  @media screen and (min-width: 767px) and (max-width: 1279px) {
    margin-right: auto;
    max-width: 340px;
  }
}

.mainPanel {
  display: flex;
  align-items: center;
  height: 62px;
}
