.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    padding: 50px 15px 25px;
  }

  @media screen and (min-width: 768px) and (max-width: 900px) {
    padding: 25px;
  }
  @media screen and (min-width: 900px) {
    padding: 30px 35px 35px;
  }

  @media screen and (min-width: 768px) {
    height: 100%;
  }

  &Page {
    @media screen and (min-width: 768px) and (max-width: 1279px) {
      padding: 25px 15px;
    }

    @media screen and (min-width: 1280px) {
      padding: 25px 0;
    }
  }

  &:not(&.containerPage) {
    @media screen and (min-width: 768px) {
      overflow-y: auto;
    }
  }
}

.title {
  margin-bottom: 25px;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.4375rem;
  color: var(--black);
}

.subtitle {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 0.9375rem;
  color: var(--black);
}

.dropdown {
  width: 100%;
  margin-bottom: 20px;

  &Container {
    display: flex;
    flex-direction: column;
  }
}

.input {
  margin-bottom: 20px;
}

.content {
  @media screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .input,
    .dropdownContainer {
      width: calc(50% - 10px);
    }
  }
}

.checkbox {
  margin-bottom: 20px;
}

.payment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  height: 60px;
  margin-bottom: 30px;
  padding: 0 15px;
  background-color: var(--primary-opacity);

  &Title {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: var(--black);
  }

  &Value {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.5625rem;
    color: var(--primary);
  }
}

.submitButton {
  flex-shrink: 0;
  text-transform: capitalize;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    width: 100%;
    margin-bottom: 15px;
  }

  @media screen and (min-width: 768px) {
    width: calc(50% - 10px) !important;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: 30px;
  }
}

.disclaimer {
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: var(--gray-dark);
}
