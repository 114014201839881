.container {
  position: relative;
  z-index: 1;
  background-color: var(--header-color);

  @media screen and (min-width: 279px) and (max-width: 1023px) {
    padding: 0 16px;
  }

  @media screen and (min-width: 1024px) {
    padding: 0 36px;
  }

  &.centerMenu {
    padding: 0;

    .mainPanel {
      position: relative;

      @media screen and (min-width: 279px) and (max-width: 1023px) {
        padding: 0 15px;
      }

      @media screen and (min-width: 1024px) and (max-width: 1279px) {
        padding-right: 15px;
      }

      @media screen and (min-width: 1024px) {
        height: 50px;
      }

      @media screen and (min-width: 1280px) {
        padding: 0 15px 0 0;
      }
    }

    .actions {
      position: absolute;

      @media screen and (min-width: 279px) and (max-width: 1279px) {
        right: 0;
      }

      @media screen and (min-width: 1280px) {
        right: 10px;
      }
    }
  }

  &:not(&.centerMenu) {
    .actions {
      margin-left: 15px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 1px;
    background-color: var(--header-border-color);
    z-index: 5;
  }
}

.logo {
  display: flex;

  img {
    max-height: 60px;
    object-fit: contain;
  }

  @media screen and (min-width: 1280px) and (max-width: 1599px) {
    img {
      max-width: 236px;
    }
  }

  @media screen and (min-width: 1600px) and (max-width: 1919px) {
    img {
      max-width: 360px;
    }
  }

  @media screen and (min-width: 1920px) and (max-width: 2559px) {
    img {
      max-width: 530px;
    }
  }

  @media screen and (min-width: 2560px) {
    img {
      max-width: 610px;
    }
  }
}

.mainPanel {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    height: 62px;
  }

  @media screen and (min-width: 768px) {
    height: 91px;
  }

  @media screen and (min-width: 1920px) {
    max-width: 1920px;
  }
}
