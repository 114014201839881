.slider {
  height: 100%;
  display: flex;
  position: relative;
  text-align: center;
  overflow-y: auto;

  img {
    object-fit: contain;
  }

  &.lightBg {
    img {
      mix-blend-mode: darken;
    }
  }

  &Link {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0 24px;
    justify-content: center;
    transition: var(--transition);
    cursor: pointer;

    &:hover {
      p,
      span {
        color: var(--header-select-color);
      }
    }
  }

  &Title {
    display: flex;
    margin-top: -5px !important;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    color: var(--black);

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    p,
    span {
      transition: var(--transition);
    }

    span {
      display: flex;
      margin-left: 5px;
      margin-top: 0 !important;
      color: var(--gray-title);
    }

    &Selected {
      p,
      span {
        color: var(--primary);
      }
    }
  }
}

.centerMenu {
  .sliderLink {
    p {
      color: var(--header-text-color);
    }
  }
}
