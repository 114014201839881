.container {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--gray-text);
}

.image {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: 240px;
  background-color: var(--gray-text);

  img {
    object-fit: cover;
    width: 100%;
  }

  &Link {
    width: 100%;
    height: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 15px 25px;
}

.title {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: var(--black);
}

.link {
  display: flex;

  &.itemWithIcon {
    .text {
      color: var(--primary);
    }

    svg {
      path {
        transition: var(--transition);
      }
    }

    &:hover {
      .text {
        color: var(--primary-dark);
      }

      svg {
        path {
          fill: var(--primary-dark);
        }
      }
    }
  }

  &Title + .itemWithIcon {
    margin-top: 10px;
  }
}

.description {
  margin-bottom: 15px;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: var(--black);
}

.itemWithIcon {
  display: flex;
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 25px;
  }

  svg {
    display: flex;
    flex-shrink: 0;
    margin-right: 10px;
    color: var(--primary);
  }
}

.text {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: var(--black);
  word-break: break-word;
}

.button {
  margin-top: auto;
}
