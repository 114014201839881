.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: var(--transition);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    height: 100%;
  }
}

.closeBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  background-color: transparent;
  z-index: 10002;

  svg {
    color: var(--gray-dark);

    path {
      transition: var(--transition);
    }
  }

  &:hover {
    svg {
      path {
        stroke: var(--black);
      }
    }
  }
}

.header {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: 50px;
  background-color: transparent;
}
