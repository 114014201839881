.iframe {
  @media screen and (min-width: 279px) and (max-width: 1023px) {
    position: relative;
    padding-top: 56.25%;
    margin: 0 15px 35px;
    width: calc(100% - 30px) !important;

    & > iframe {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @media screen and (min-width: 1024px) {
    min-height: 300px;
    margin: 0 auto 50px;
    padding: 0 15px;
  }
}
