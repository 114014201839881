.main {
  display: flex;
  margin-top: auto;

  &Content {
    width: 320px;
    flex-shrink: 0;
    background: var(--footer-map-color);
    padding: 25px;

    &Hr {
      height: 1px;
      background: #424242;
      margin: 25px 0;
    }
    h4 {
      font-size: 1rem;
      color: #fff;
      padding-bottom: 15px;
    }
  }

  @media screen and (max-width: 767px) {
    display: grid;

    &Content {
      width: 100%;
      order: 1;
      padding: 15px;
    }
  }
}

.map {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    width: 100%;
    min-height: 300px;
  }

  @media screen and (min-width: 768px) {
    width: calc(100% - 320px);
  }

  &Items {
    position: absolute;
    top: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 279px) and (max-width: 767px) {
      transform: translateY(-120%);
    }

    @media screen and (min-width: 768px) {
      transform: translateY(-130%);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.address {
  margin-bottom: 10px;
  padding: 15px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  text-align: center;
  color: var(--black);
  background-color: var(--white);
  box-shadow:
    0px 5px 10px rgba(0, 0, 0, 0.08),
    0px 10px 20px rgba(0, 0, 0, 0.04);
  border-radius: 10px;

  @media screen and (max-width: 767px) {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  @media screen and (min-width: 768px) {
    width: 180px;
  }
}

.pin {
  position: relative;
  display: flex;
  width: 30px;
  height: 45px;

  &:before {
    content: "";
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--primary);
  }

  &:after {
    position: absolute;
    bottom: 0;
    left: calc(50% - 1.25px);
    content: "";
    width: 2.5px;
    height: 25px;
    background-color: var(--primary);
  }
}

.getDirections {
  position: absolute;
  margin-top: auto;
  text-transform: uppercase;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    bottom: 30px;
    max-width: 345px;
    width: calc(100% - 30px);
    margin: 0 15px;
  }

  @media screen and (min-width: 768px) {
    bottom: 35px;
    max-width: 250px;
  }

  svg {
    path {
      stroke: var(--white);
    }
  }
}

.departments {
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  &Item {
    font-size: 1rem;
    line-height: 1.5;
    margin-right: 30px;
    border-bottom: 1px solid transparent;
    color: var(--gray-dark);
    transition: var(--transition);
    cursor: pointer;

    &:hover {
      color: var(--white);
    }

    &:last-child {
      margin-right: 0;
    }

    &Active {
      color: var(--white);
      border-color: var(--white);
    }
  }
}

.schedule,
.phones {
  li {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: var(--gray-dark);
    line-height: 1.5625rem;
    font-size: 0.875rem;

    a {
      color: #979797;
    }
  }
}
