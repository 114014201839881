@import "../../../styles/fonts.scss";
@import "../../../styles/_mixins.scss";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  padding: 0 24px;
  font-weight: 500 !important;
  color: var(--primary);
  background-color: var(--white);
  outline: none;
  user-select: none;
  border: 1px solid var(--primary);
  transition: var(--transition);

  @media screen and (max-width: 1059px) {
    @include apply-font(preformatted, mobile, regular);
  }

  @media screen and (min-width: 1060px) {
    @include apply-font(preformatted, desktop, regular);
  }

  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    background-color: var(--primary);
    color: var(--white);

    &.primary {
      background-color: var(--primary-dark);
      border-color: var(--primary-dark);
    }
  }

  &.primary {
    background-color: var(--primary);
    color: var(--white);
  }

  &.disabled {
    pointer-events: none;
    background-color: var(--gray-secondary);
    border-color: var(--gray-secondary);
    color: var(--white);
  }

  & > svg {
    margin-right: 5px;
  }
}
