.container {
  @media screen and (min-width: 279px) and (max-width: 1279px) {
    padding: 30px 15px 40px;
  }

  @media screen and (min-width: 1280px) and (max-width: 1919px) {
    padding: 30px 105px;
  }

  @media screen and (min-width: 1920px) {
    padding: 40px 0 40px;
    margin: 0 auto;
    max-width: var(--content-width-big);
    width: 100%;
  }
}

.title {
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.6875rem;
  color: var(--black);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    margin-bottom: 20px;
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 25px;
  }
}

.text {
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 10px;
  color: var(--gray-dark);

  a {
    color: var(--primary);
  }
}
