.category {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.submenuContainer {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  transform: translateY(100%);
  z-index: 5;
}

.shop_menu {
  background-color: var(--next-level-menu-color);
  left: 0;
  height: 105px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-top: 10px;
  }
}

.subcategory,
.shop_menu {
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 1px;
    background-color: var(--header-border-color);
    z-index: 5;
  }
}

.navigation {
  display: flex;
  flex: 1;
  gap: 24px;
  height: 100%;
  margin: 0 24px;
  justify-content: space-evenly;
}

.link {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 15px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25;
  @media screen and (min-width: 1600px) {
    font-size: 1.2rem;
    line-height: 1.45;
  }

  @media screen and (min-width: 1920px) {
    font-size: 1.4rem;
    line-height: 1.65;
  }

  color: var(--header-text-color);
  background-color: transparent;
  transition: var(--transition);

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &:before {
    position: absolute;
    bottom: 0px;
    left: 0;
    content: "";
    width: 100%;
    height: 2px;
    background-color: transparent;
    transition: var(--transition);
    z-index: 6;
  }

  &:hover {
    color: var(--header-select-color);
  }

  &__active {
    &:before {
      background-color: var(--header-select-color);
    }
  }

  &Container {
    position: relative;
    height: 100%;
  }
}

.subcategory {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: var(--next-level-menu-color);
  overflow-x: auto;
  padding: 0 15px;
  transition: var(--transition);

  &Btn {
    background-color: transparent !important;
    color: var(--header-select-color);
    border-color: var(--header-select-color);
    transition: var(--transition);
    height: inherit !important;
    min-height: 40px;
    width: fit-content !important;
    min-width: 200px;
    padding: 0 24px;

    &:hover {
      background-color: var(--header-select-color) !important;
      border-color: var(--header-select-color) !important;
    }
  }

  &Item {
    display: flex;
    margin-right: 30px;
    position: relative;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }

  &Link {
    position: relative;
    display: flex;
    height: 60px;
    align-items: center;
    z-index: 6;

    &:hover {
      .subcategoryLinkText {
        color: var(--header-select-color);
      }
    }

    &Active {
      &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 2px;
        background-color: var(--header-select-color);
        transition: var(--transition);
        z-index: 1;
      }
    }

    &Text {
      font-size: 1rem;
      line-height: 1.0625rem;
      @media screen and (min-width: 1600px) {
        font-size: 1.2rem;
        line-height: 1.26235;
      }

      @media screen and (min-width: 1920px) {
        font-size: 1.4rem;
        line-height: 1.46235;
      }

      color: var(--header-text-color);
      transition: var(--transition);
    }

    &Image {
      object-fit: contain;
      max-height: 70px;
      max-width: 105px;
      width: 100%;
      height: 100%;
    }

    &WithImage {
      height: 105px;
      flex-direction: column;
      justify-content: center;

      .subcategoryLinkText {
        margin-top: -5px;
        font-size: 0.75rem;
        line-height: 0.9375rem;
      }
    }
  }
}
