.dot {
  display: flex;
  width: 4px;
  height: 4px;
  margin: 0 7px;
  background-color: var(--dark);
  border-radius: 50%;
}

.department {
  display: flex;
  align-items: center;

  @media screen and (min-width: 279px) and (max-width: 499px) {
    padding-left: 15px;
  }

  &Content {
    display: flex;
    align-items: center;

    &:last-child {
      .dot {
        display: none;
      }
    }
  }

  &biggerText {
    @media screen and (min-width: 279px) and (max-width: 499px) {
      grid-template-columns: 60px 1fr 15px;
    }

    @media screen and (min-width: 500px) {
      grid-template-columns: 80px 1fr;
    }
  }

  &Text {
    display: grid;
    font-weight: 500;
    line-height: 0.9375rem;
    white-space: nowrap;
    color: var(--header-additional-text-color);
    transition: var(--transition);

    @media screen and (min-width: 279px) and (max-width: 499px) {
      grid-template-columns: 55px 1fr 15px;
    }

    @media screen and (min-width: 500px) {
      grid-template-columns: 65px 1fr;
    }

    @media screen and (min-width: 279px) and (max-width: 399px) {
      font-size: 12px;
    }

    @media screen and (min-width: 400px) and (max-width: 1023px) {
      font-size: 0.75rem;
    }

    @media screen and (min-width: 1024px) {
      font-size: 0.875rem;
    }
  }
}
