.container {
  position: relative;
  z-index: 1;
  border-bottom: 1px solid var(--header-border-color);
  background-color: var(--header-color);

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    padding: 0 15px;
  }

  @media screen and (min-width: 1280px) {
    padding: 0 25px;
  }
}

.logo {
  display: flex;
  margin: 0 auto;
  max-height: 44px;
  height: 100%;
}

.mainPanel {
  display: flex;
  align-items: center;
  height: 60px;
}

