.container {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    padding: 45px 15px 60px;
  }

  @media screen and (min-width: 1280px) {
    padding: 90px 105px;
    max-width: var(--content-width-big);
    margin: 0 auto;
  }
}

.title {
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 1.875rem;
  color: var(--black);

  @media screen and (min-width: 768px) {
    text-align: center;
  }
}

.content {
  display: grid;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, minmax(220px, 2fr));
    gap: 20px;
  }

  @media screen and (min-width: 768px) and (max-width: 1279px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }

  @media screen and (min-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 35px 30px;
  }
}
